import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import Logo from "../../Assets/Svg/Logo.svg";

export default function EnglishHeader() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.endsWith("/ar");
    const basePath = isArabic ? currentPath.slice(0, -3) : currentPath;
    const newPath = isArabic ? basePath : `${basePath}${basePath.endsWith("/") ? "ar" : "/ar"}`;
    navigate(newPath, { replace: true });
  };

  const handleScroll = (event, targetId) => {
    const isMobile = window.innerWidth <= 768;

    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;

      const offset = isMobile ? (-20 * window.innerWidth) / 100 : (-10 * window.innerWidth) / 100;

      window.scrollTo({
        top: targetPosition + offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="EnHeaderContainer">
        <div className="EnHeaderL">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="EnHeaderR">
          <div className="EnHeaderRC">
            <div className="EnHeaderRF"></div>
            <a href="#HomeS2T4C2" onClick={(e) => handleScroll(e, "HomeS3")}>
              Features
            </a>
          </div>
          <div className="EnHeaderRL"></div>
          <div className="EnHeaderRC">
            <div className="EnHeaderRF"></div>
            <a href="#HomeS5" onClick={(e) => handleScroll(e, "HomeS5")} className="LinkSpan">
              About <span> Us</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
