import React from "react";
import "./HomeS1New.css";
import LogoS1P1 from "../../../Assets/Svg/HomeS1P1.svg";
import GooglePlay from "../../../Assets/Svg/GooglePlay.svg";
import AppStore from "../../../Assets/Svg/AppStore.svg";
import HomeS1P2New from "../../../Assets/Svg/HomeS1P2New.svg";
import HomeS1P2New2 from "../../../Assets/Svg/HomeS1Compo/Hom1S1New2.svg";
import HomeS1P2New4 from "../../../Assets/Svg/HomeS1Compo/Hom1S1New4.svg";
import HomeS1P2New3 from "../../../Assets/Svg/HomeS1Compo/Hom1S1New3.svg";
export default function HomeS1New() {
  return (
    <>
      <div className="HomeS1New">
        {/*  */}
        <div className="HomeS1NewFirst">
          <div className="HomeS1NewP1">
            <div className="HomeS1NewP1V1">
              <img src={LogoS1P1} alt="" className="HomeS1NewP1V1Img" />
            </div>
            <div className="HomeS1NewP1V2">
              Never Miss a Deadline Again. <br />
              Scan, track, and stay organized.
            </div>
            <div className="HomeS1NewP1V3">
              <a href="/">
                <img src={GooglePlay} alt="" className="HomeS1NewP1V3Img" />
              </a>
              <a href="/">
                <img src={AppStore} alt="" className="HomeS1NewP1V3Img" />
              </a>
            </div>
          </div>
          {/*  */}
          <div className="HomeS1NewP2">
            <div className="HomeS1NewP2ContentP1">
              <img src={HomeS1P2New2} alt="" className="HomeS1NewP2ImgMain" />
            </div>
            <div className="HomeS1NewP2ContentP2">
              <img src={HomeS1P2New4} alt="" className="HomeS1NewP2ImgLines" />
            </div>
            <div className="HomeS1NewP2Last">
              <img src={HomeS1P2New3} alt="" className="HomeS1NewP2Img" />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="HomeS1NewP3">
          <div className="scrolling-content-new">
            Scan All your documents <span className="dot"></span>App Reminder <span className="dot"></span>
            Document Organization <span className="dot"></span> Documents Scan & Ocr <span className="dot"></span>Reminder System
          </div>
        </div>
      </div>
    </>
  );
}
