import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import EnglishHeader from "./Components/Header/EnglishHeader";
import ArabicHeader from "./Components/Header/ArabicHeader";
import EnglishHeaderMobile from "./Components/Header/EnglishHeaderMobile";
import ArabicHeaderMobile from "./Components/Header/ArabicHeaderMobile";
import EnglishFooter from "./Components/Footer/EnglishFooter";
import ArabicFooter from "./Components/Footer/ArabicFooter";
import EnglishFooterMobile from "./Components/Footer/EnglishFooterMobile";
import ArabicFooterMobile from "./Components/Footer/ArabicFooterMobile";
import Home from "./Pages/Home/Index";

const routes = [{ path: "/", enComponent: <Home />, arComponent: <Home /> }];

export default function LanguageRoutes() {
  const location = useLocation();
  const isArabic = location.pathname.endsWith("/ar");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const HeaderComponent = isSmallScreen ? (isArabic ? ArabicHeaderMobile : EnglishHeaderMobile) : isArabic ? ArabicHeader : EnglishHeader;

  const FooterComponent = isSmallScreen ? (isArabic ? ArabicFooterMobile : EnglishFooterMobile) : isArabic ? ArabicFooter : EnglishFooter;

  return (
    <>
      <EnglishHeader />
      <Routes>
        {routes.map(({ path, enComponent, arComponent }, index) => (
          <Route key={index} path={path} element={isArabic ? arComponent : enComponent} />
        ))}
        {routes.map(({ path, arComponent }, index) => (
          <Route key={index} path={`${path}/ar`} element={arComponent} />
        ))}
      </Routes>
      <FooterComponent />
    </>
  );
}
