import React from "react";
import "./Home.css";
import HomeS1New from "./HomeS1New/HomeS1New";
import HomeS2 from "./HomeS2/HomeS2";
import HomeS3 from "./HomeS3/HomeS3";
import HomeS4 from "./HomeS4/HomeS4";
import HomeS5 from "./HomeS5/HomeS5";
export default function HomeContent() {
  return (
    <>
      <div className="Home_Container">
        <HomeS1New />
        <HomeS2 />
        <HomeS3 />
        <HomeS4 />
        <HomeS5 />
      </div>
    </>
  );
}
