import React, { useState, useEffect } from "react";
import "./HomeS4.css";
import HS4C1P2ImgL1 from "../../../Assets/Svg/HomeS4/HS4C1P2L1.svg";
import HS4C1P2ImgL2 from "../../../Assets/Svg/HomeS4/HS4C1P2L2.svg";
import HS4C1P2ImgL3 from "../../../Assets/Svg/HomeS4/HS4C1P2L3.svg";
import HS4C1P2ImgL4 from "../../../Assets/Svg/HomeS4/HS4C1P2L4.svg";
import HS4C1P2LMob from "../../../Assets/Svg/HomeS4/HS4C1P2LMob.svg";
import HomeS1P2 from "../../../Assets/Svg/HomeS1P2.svg";

export default function HomeS4() {
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <div className="HomeS4">
        <div className="HS4C1P1">
          <span>xam</span> Rotating phases
        </div>
        <div className="HS4C1">
          <div className="HS4C1P2">
            <div className="HS4C1P2Box1">
              <div className="HS4C1P2Box1C">
                <img src={isMobile ? HS4C1P2LMob : HS4C1P2ImgL1} alt="" className="HS4C1P2ImgL1" />
                <div className="HS4C1P2Box1CText">
                  (Scan - <span>OCR</span>)
                </div>
              </div>
              <HS4C1P2Circle
                Number={<>01</>}
                Text={
                  <>
                    Scan <br /> Document
                  </>
                }
              />
            </div>
            <div className="HS4C1P2Box2">
              <div className="HS4C1P2Box2C">
                <img src={isMobile ? HS4C1P2LMob : HS4C1P2ImgL2} alt="" className="HS4C1P2ImgL2" />
                <div className="HS4C1P2Box2CText">
                  (<span>Store</span> - <span>Classify</span>)
                </div>
              </div>
              <HS4C1P2Circle
                Number={<>02</>}
                Text={
                  <>
                    Get It <br />
                    Organized
                  </>
                }
              />
            </div>
            <div className="HS4C1P2Box3">
              <div className="HS4C1P2Box3C">
                <img src={isMobile ? HS4C1P2LMob : HS4C1P2ImgL3} alt="" className="HS4C1P2ImgL1" />
                <div className="HS4C1P2Box3CText">
                  (<span>Recognize</span>)
                </div>
              </div>
              <HS4C1P2Circle
                Number={<>03</>}
                Text={
                  <>
                    Get Reminded <br />
                    to Renew
                  </>
                }
              />
            </div>
            <div className="HS4C1P2Box4">
              <div className="HS4C1P2Box4C">
                <img src={isMobile ? HS4C1P2LMob : HS4C1P2ImgL4} alt="" className="HS4C1P2ImgL4" />
                <div className="HS4C1P2Box4CText">
                  (<span>Notify</span> – Renew)
                </div>
              </div>
              <HS4C1P2Circle
                Number={<>04</>}
                Text={
                  <>
                    Never Have an Expired <br /> Document Again.
                  </>
                }
              />
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="HS4C2">
          <div className="HS4C2P1">
            <span>xam</span> offering solution
          </div>
          <div className="HS4C2P2">
            <div className="HS4C2P2L">
              <div className="HS4C2P2LP2">
                <div className="HS4C2P2LP2Content">
                  <img src={HomeS1P2} alt="" className="HS4C2P2LP2Img" />
                  <div className="HS4C2P2LP2Text">Scan & Remind</div>
                </div>
              </div>
            </div>
            {/*  */}
            {/*  */}
            <div className="HS4C2P2R">
              <HS4C2P2RBox
                LText="A"
                LBGColor="#FF4A4A"
                Para1={
                  <>It has been determined that a necessary document (driving license, passport, ID, membership, etc.) is no longer valid due to expiration.</>
                }
                Para2={<>offering solution by notification system with Expiration and time. </>}
              />
              <HS4C2P2RBox
                LText="B"
                LBGColor="#7B6AF4"
                Para1={
                  <>
                    After waiting for 2 days, I would like to request a copy of my (driving license, passport, ID, membership, etc.) to complete my application.
                  </>
                }
                Para2={<>offering solution by organizing your data system to be able to reach them easily.</>}
              />
              <HS4C2P2RBox
                LText="C"
                LBGColor="#C4E3E6"
                Para1={<>Search online for the official website of the issuing authority. Look for a section related to document renewal or replacement.</>}
                Para2={<>Offering a solution for the date, renewal, and expiration date.</>}
              />
              <HS4C2P2RBox
                LText="D"
                LBGColor="#FFD96E"
                Para1={<>Duplicated multiple document sources like camera, internal storage, and received files.</>}
                Para2={<>offering solution with a system that collects, organizes, and eliminates duplicate data. </>}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function HS4C1P2Circle({ Number, Text }) {
  return (
    <>
      {" "}
      <div className="HomeS4P2">
        <div className="HomeS4P2Content">
          <div className="HomeS4P2Number">{Number}</div>
          <div className="HomeS4P2Text">{Text}</div>
        </div>
      </div>
    </>
  );
}
function HS4C2P2RBox({ LText, LBGColor, Para1, Para2 }) {
  return (
    <>
      <div className="HS4C2P2RBoxC">
        <div className="P2RBoxCL" style={{ backgroundColor: LBGColor }}>
          {LText}
        </div>
        <div className="P2RBoxCR">
          <div>{Para1}</div>
          <div>
            <span>xam</span> {Para2}
          </div>
        </div>
      </div>
    </>
  );
}
