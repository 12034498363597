import React from "react";
import "./HomeS3.css";
import HomeS3P2BImg1 from "../../../Assets/Svg/HomeS2/HomeS2T4C2Img1.svg";
import HomeS3P2BImg2 from "../../../Assets/Svg/HomeS3P2BImg1.svg";
import HomeS3P2BImg3 from "../../../Assets/Svg/HomeS3P2BImg3.svg";
export default function HomeS3() {
  return (
    <>
      <div id="HomeS3" className="HomeS3">
        <div className="HomeS3P1">
          <div className="HomeS3P1B1">Other Features</div>
          <div className="HomeS3P1B2">
            <div className="HomeS3P1B2B">
              <span>xam</span> advanced OCR technology ensures precise, detailed, and efficient document scanning, expiration dates reminders and data
              documentation.
            </div>
          </div>
        </div>
        {/*  */}
        <div className="HomeS3P2">
          {/*  */}
          <HomeS3Box
            BoxHeight="20vw"
            ImgSrc={HomeS3P2BImg1}
            ImgWidth="8vw"
            ImgWidthMob="25vw"
            Head={<>Documents Ocr</>}
            Para={<>OCR technology precisely extracts text data like name, expiry date, etc. from scanned documents.</>}
          />
          <HomeS3Box
            BoxHeight="20vw"
            BoxHeightMob="70vw"
            ImgSrc={HomeS3P2BImg2}
            ImgWidth="8vw"
            ImgWidthMob="25vw"
            Head={<>Document Organization</>}
            Para={<>Keep your documents organized in separate folders.</>}
          />
          <HomeS3Box
            BoxHeight="20vw"
            ImgSrc={HomeS3P2BImg3}
            ImgWidth="8vw"
            ImgWidthMob="25vw"
            Head={<>Expiration Tracking</>}
            Para={<>Helps you keep an eye on every expiration date and documents renewal.</>}
          />
          {/*  */}
        </div>
      </div>
    </>
  );
}
function HomeS3Box({ BoxHeightMob, BoxHeight, Number, ImgSrc, ImgAlt, ImgWidth, ImgWidthMob, Head, Para }) {
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <div className="HomeS3Box" style={{ height: isMobile ? BoxHeightMob : BoxHeight }}>
        <div className="HS3BP1N">{Number}</div>
        <div className="HS3BP1">
          <img src={ImgSrc} alt={ImgAlt} className="HS3BP1Img" style={{ width: isMobile ? ImgWidthMob : ImgWidth }} />
        </div>
        <div className="HS3BP2">{Head}</div>
        <div className="HS3BP3S">{Para}</div>
      </div>
    </>
  );
}
