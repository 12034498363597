import React, { useState, useEffect } from "react";
import "./HomeS2.css";
import HomeS2N1 from "../../../Assets/Svg/HomeS2N1.svg";
import HomeS2N2 from "../../../Assets/Svg/HomeS2N2.svg";
import HomeS2P3B1 from "../../../Assets/Svg/HomeS2/HomeS2P3B1.svg";
import HomeS2P3B2 from "../../../Assets/Svg/HomeS2/HomeS2P3B2.svg";
import HomeS2P3B3 from "../../../Assets/Svg/HomeS2/HomeS2P2V2.svg";
import HomeS2P1V2 from "../../../Assets/Svg/HomeS2/HomeS2P2V2.svg";
import HomeS2S1 from "../../../Assets/Images/HomeS2S1.png";
import HomeS2S2 from "../../../Assets/Images/HomeS2S2.png";
import HomeS2S3 from "../../../Assets/Images/HomeS2S3.png";
import HomeS2S4 from "../../../Assets/Images/HomeS2S4.png";
import HomeS2S5 from "../../../Assets/Images/HomeS2S5.png";
import HomeS2S6 from "../../../Assets/Images/HomeS2S6.png";
import HomeS2S7 from "../../../Assets/Images/HomeS2S7.png";
import HomeS2S8 from "../../../Assets/Images/HomeS2S8.png";
import HomeS3P2BImg2 from "../../../Assets/Svg/HomeS3P2BImg3.svg";
import HomeS2T4C2Img1 from "../../../Assets/Svg/HomeS2/HomeS2T4C2Img1.svg";
import HomeS2T4C2Img2 from "../../../Assets/Svg/HomeS2/HomeS2T4C2Img2.svg";
import HomeS2T4C2Img3 from "../../../Assets/Svg/HomeS2/HomeS2T4C2Img3.svg";

export default function HomeS2() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [HomeS2S1, HomeS2S2, HomeS2S3, HomeS2S4, HomeS2S5, HomeS2S6, HomeS2S7, HomeS2S8];
  const altTexts = ["aaa"];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <div className="HomeS2">
        {/*  */}
        <div className="HomeS2T1">
          <div className="HomeS2P1">
            <div className="HomeS2P1V1">
              <span>xam</span> overview
            </div>

            <div className="HomeS2P2Mob">
              <img src={images[currentImage]} alt={altTexts[currentImage]} className="HomeS2P2Img" />
            </div>
            {/*  */}
            <div className="HomeS2P1V2">
              <img src={HomeS2P1V2} alt="" className="HomeS2P1V2Img" />
              <div className="HomeS2P1V2T">
                Just one click to scan your Documents and <span>xam</span> will Remind you to Renew
              </div>
            </div>
            {/*  */}
            <div className="HomeS2P1V3">
              Xam is your digital/AI assistant that will be taking care of your documents, all you need is to scan all your documents, many features will easily
              help you organize them and you will receive reminding notification for each document renewal dates.{" "}
            </div>
          </div>
          {/*  */}
          <div className="HomeS2P2 web">
            <img src={images[currentImage]} alt={altTexts[currentImage]} className="HomeS2P2Img" />
          </div>
        </div>
        {/*  */}
        <div className="HomeS2T2"></div>
        {/*  */}
        <div className="HomeS2T3">
          <HomeS2Box BoxHeight="15vw" ImgSrc={HomeS2P3B1} ImgAlt="" Head={<>Security</>} Para={<>Your data is safe with our advanced security measures.</>} />
          <HomeS2Box
            BoxHeight="15vw"
            ImgSrc={HomeS2P3B2}
            ImgAlt=""
            Head={<>Customization</>}
            Para={<>With our app, you can tailor data, assign specific document, and schedule personalized renewal reminders</>}
          />
          <HomeS2Box
            BoxHeight="15vw"
            ImgSrc={HomeS2P3B3}
            ImgAlt=""
            Head={<>Ease of Use</>}
            Para={<>A user-friendly interface makes document management a breeze.</>}
          />
        </div>
        {/*  */}
        <div className="HomeS2T4">
          <div className="HomeS2T4C1">
            <div className="HomeS2T4C1D1">
              Scan , <span>File</span> & Remind
            </div>
            <div className="HomeS2T4C1D2">
              Just <span>three</span> simple steps!
              <br />
              Scan your document and let <span>xam</span> send you a reminder and renewal time.
            </div>
          </div>
          <div className="HomeS2T4C2" id="HomeS2T4C2">
            <HomeS2Box
              BoxHeight="22vw"
              Number={<>01</>}
              ImgSrc={HomeS2T4C2Img1}
              ImgAlt=""
              ImgWidth="8vw"
              Head={<> Scan your documents</>}
              Para={<>Our app supports a wide range of document types, from receipts and invoices to business cards and ID </>}
            />
            <HomeS2Box
              BoxHeight="22vw"
              Number={<>02</>}
              ImgSrc={HomeS2T4C2Img2}
              ImgAlt=""
              ImgWidth="8vw"
              Head={<> File</>}
              Para={<>All your documents will be organized and sorted out and you can easily track any of them </>}
            />
            <HomeS2Box
              BoxHeight="22vw"
              Number={<>03</>}
              ImgSrc={HomeS2T4C2Img3}
              ImgAlt=""
              ImgWidth="7vw"
              Head={<>Reminding</>}
              Para={
                <>
                  Our app offers flexible reminder options, including time-based and location-based alerts, to ensure you never miss a deadline or important
                  tasks{" "}
                </>
              }
            />
          </div>
        </div>
        {/*  */}
      </div>
    </>
  );
}
function HomeS2Box({ BoxHeightMob, BoxHeight, Number, ImgSrc, ImgAlt, ImgWidth, ImgWidthMob, Head, Para }) {
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <div className="HomeS2Box" style={{ height: isMobile ? BoxHeightMob : BoxHeight }}>
        <div className="HS2BP1N">{Number}</div>
        <div className="HS2BP1">
          <img src={ImgSrc} alt={ImgAlt} className="HS2BP1Img" style={{ width: isMobile ? ImgWidthMob : ImgWidth }} />
        </div>
        <div className="HS2BP2">{Head}</div>
        <div className="HS2BP3S">{Para}</div>
      </div>
    </>
  );
}
